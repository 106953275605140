import {
	animationFeatures,
	animationProjects,
	animationWhy, developerSteps, dragSlide
} from "@ui/animations";

import { accordion, ajaxLoadMore, analyticsTable, copyToClipboard, orderBalls, playVideo } from "@modules/tricks";

export default [
	{
		namespace: "home",
		afterEnter() {
			animationFeatures();
			animationProjects();
			animationWhy();
			dragSlide();
			orderBalls();
		},
	},
	{
		namespace: "page-templates/community",
		afterEnter() {
			dragSlide();
			animationProjects();
			orderBalls();
		},
	},
	{
		namespace: "page-templates/about",
		afterEnter() {
			accordion();
			playVideo();
		},
	},
	{
		namespace: "page-templates/developer",
		afterEnter() {
			developerSteps();
			playVideo();
		},
	},
	{
		namespace: "page-templates/analytics",
		afterEnter() {
			analyticsTable();
		},
	},
	{
		namespace: "archive",
		afterEnter() {
			dragSlide();
			ajaxLoadMore();
		},
	},
	{
		namespace: "archive-post",
		afterEnter() {
			ajaxLoadMore();
		},
	},
	{
		namespace: "single-post",
		afterEnter() {
			copyToClipboard();
		},
	},
];
