import { openContact, toggleNav } from "@modules/tricks";
import { setupHovers } from "@utils/hovers";
import ImagesLoaded from "@utils/imagesloaded";
import { setupAnimations } from "@utils/splittext";
import { gsap } from "gsap";
import jQuery from "jquery";

const siteTransition = jQuery("[data-site-transition]");

class SiteTransition {
	constructor() {
		this.name = "site-transition";

		this._imagesLoaded = false;
		this._imgLoader = null;
		this._onImagesLoaded = this._onImagesLoaded;
	}

	async beforeLeave({ next }) {
		this._imagesLoaded = false;
		// if next.container is not loaded, try again during `beforeEnter` hook.
		if (!next.container) return;
		// preload images from next container during leave transition
		// do not wait to images preloading to finish to start leave transition
		this._imgLoader = new ImagesLoaded(next.container, this._onImagesLoaded);
	}

	async beforeEnter(data) {
		// ScrollTrigger.getAll().forEach((t) => t.kill());
		// this.initCF7(data.next.container);

		const cf_selector = "div.wpcf7 > form";
		const cf_forms = jQuery(data.next.container).find(cf_selector);
		if (cf_forms.length > 0) {
		jQuery(cf_selector).each(function () {
			const $form = jQuery(this);
			wpcf7.init($form[0]);
		});
		}

		// if images are loaded, skip here
		if (this._imagesLoaded === true || !this._imagesLoaded) return;

		return new Promise(resolve => {
			// if imagesLoaded has not been initialized, because next.container was null in `beforeLeave` hook.
			if (!this._imgLoader)
				this._imgLoader = new ImagesLoaded(
					data.next.container,
					this._onImagesLoaded,
				);

			// wait until images are loaded
			this._imgLoader.once("always", resolve);
		});
	}

	afterEnter() {
		jQuery("video[autoplay]").each(function () {
			jQuery(this).trigger('play');
		});
		setupAnimations();
		setupHovers();
	}

	async leave(data) {
		this.transitionIn(data.current.container);
		await this.delay(1000);
		data.current.container.remove();
	}

	async enter(data) {
		this.transitionOut(data.next.container);
	}

	transitionIn({ container }) {
		const tl = gsap.timeline({
			defaults: {
				duration: 0.5,
				ease: "power2.inOut",
			},
		});
		tl.to(siteTransition, { autoAlpha: 1 });
		return tl;
	}

	transitionOut({ container }) {
		const tl = gsap.timeline({
			defaults: {
				duration: 0.5,
				ease: "power2.inOut",
			},
			onStart: async () => {
				await this.initScript();
			},
		});
		tl.to(siteTransition, { autoAlpha: 0 });
		return tl;
	}

	_onImagesLoaded() {
		this._imagesLoaded = true;

		this._imgLoader.destroy();
		this._imgLoader = null;
	}

	delay(n) {
		n = n || 2000;
		return new Promise(resolve => {
			setTimeout(resolve, n);
		});
	}

	initScript() {
		toggleNav();
		openContact();
	}
}

export default SiteTransition;
