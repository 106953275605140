import { createElement, createElementNS, query, queryAll, getCursorPoint, setProps, hasClass, addClass, removeClass } from "../ponce/util/functions";

const inertia = .1;
let maskBts;

const MaskedButton = function (el) {
	let point, lerpPoint, running;

	const enterHandler = (e) => {
		const el = e.target;
		const container = query('.container', el);
		const svg = query('svg', el);
		const hasLoop = hasClass(el, 'loop');
		if (!svg) {
			const clone = hasClass(el, 'clone');
			const index = maskBts.indexOf(el);
			const maskId = `circle_mask_${index}`;
			const circle_1 = createElementNS('circle');
			const svg = createElementNS('svg');

			if (clone) {
				const clipPath = createElementNS('clipPath', { id: maskId });
				clipPath.appendChild(circle_1);
				svg.appendChild(clipPath);
			} else svg.appendChild(circle_1);

			el.appendChild(svg);

			if (container && clone) {
				const style = `clip-path: url(#${maskId});`;
				const cloneContainer = createElement('div', { class: 'clone-container', style: style });
				const containerClone = container.cloneNode(true);
				cloneContainer.appendChild(containerClone);
				el.appendChild(cloneContainer);
			}
		}
		el.addEventListener('mousemove', moveHandler);
		el.addEventListener('mouseleave', leaveHandler, { once: true });

		if (hasLoop && container) {
			const child = container.children[0];
			setProps(child, { '--y': '-100%', 'transition': '', 'transition-timing-function': '' });
			child.addEventListener('transitionend', () => {
				setProps(child, { '--y': '100%', 'transition': 'unset' });
				setTimeout(() => setProps(child, { '--y': '0%', 'transition': '', 'transition-timing-function': 'ease-out' }), 1);
			}, { once: true });
		}

		const r = Math.hypot(el.clientWidth, el.clientHeight);
		setProps(el, { '--r': `${r}px` });

		const circle = query('circle', el);
		circle.removeEventListener('transitionend', reset);

		moveHandler(e);
		if (!running) {
			running = true;
			render();
		}
		addClass(el, 'hover');
	}
	const leaveHandler = (e) => {
		const el = e.target;
		const circle = query('circle', el);
		setProps(el, { '--r': `0px` });
		el.removeEventListener('mousemove', moveHandler);
		circle.addEventListener('transitionend', reset);
		removeClass(el, 'hover');
	}
	const reset = (e) => {
		point = lerpPoint = null;
		running = false;
	}
	const moveHandler = (e) => {
		point = getCursorPoint(e);
		if (!lerpPoint) lerpPoint = point;
	}

	const render = () => {
		if (running) {
			lerpPoint.x += (point.x - lerpPoint.x) * inertia;
			lerpPoint.y += (point.y - lerpPoint.y) * inertia;
			setProps(el, { '--x': `${lerpPoint.x}px`, '--y': `${lerpPoint.y}px` });
			requestAnimationFrame(render);
		}
	}

	el.addEventListener('mouseenter', enterHandler);
}


const setupHovers = () => {
	maskBts = [...queryAll('.mask-bt')];
	maskBts.forEach(el=>{
		if(!el.instance) el.instance = new MaskedButton(el);
	});
}

export { setupHovers };
