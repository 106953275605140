import { $, $$ } from "@utils/dom";
import jQuery from "jquery";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { MotherLoader, MotherLoaderEvent } from "../ponce/controller/loader";
import { setupAnimations } from "@utils/splittext";
import { setupHovers } from "@utils/hovers";
import { toggleNav, openContact } from "@modules/tricks";
import { gsap, Quad, Quart } from "gsap/all";
import { query, queryAll } from "../ponce/util/functions";
gsap.registerPlugin(DrawSVGPlugin);

const SELECTOR = "[data-site-loader]";

class SiteLoader {
	constructor() {
		this.el = $(SELECTOR);
		this.name = "loader";
	}

	beforeOnce({ next }) {
		const loader = new MotherLoader({
			verbose: true//process.env.NODE_ENV === "development",
		});
		loader.scan(next.container);

		const mainWrapperEl = query(".main-wrapper");
		const siteLoaderEl = query(".site-loader");
		const brandEl = query(".brand", siteLoaderEl);
		const circlePath = queryAll(".preloader svg path", siteLoaderEl);
		const textEl = query(".preloader .number div", siteLoaderEl);
		const linesEL = queryAll(".line", siteLoaderEl);

		mainWrapperEl.classList.remove("hide");
		siteLoaderEl.classList.remove("hide");
		gsap.set(circlePath, { drawSVG: 0 });
		// gsap.set(linesEL, { width: "0%" });
		gsap.fromTo(
			textEl,
			{ y: "110%" },
			{ y: 0, duration: 1.5, ease: Quart.easeInOut },
		);
		gsap.fromTo(
			brandEl,
			{ opacity: 0 },
			{ opacity: 1, duration: 1, ease: Quad.easeInOut },
		);

		gsap.set(loader, { delay: 1, onComplete: loader.start });

		const totalFrames = 150;
		const baseUrl =
			"./wp-content/themes/rly_network/dist/images/features_animation/";
		for (let i = 0; i <= totalFrames; i++)
			loader.add(`${baseUrl}${i}.webp`, { id: `frame_${i}` });

		loader.addEventListener(MotherLoaderEvent.PROGRESS, e => {
			const pct = Math.floor(e.progress * 100);
			textEl.innerHTML = `${pct}%`;
			gsap.to(circlePath, {
				drawSVG: `${pct}%`,
				duration: 0.2,
				ease: Quad.easeOut,
			});
			gsap.to(linesEL, { width: `${pct}%`, duration: 0.2, ease: Quad.easeOut });
		});

		return new Promise(resolve => {
			window.loader = loader;
			loader.addEventListener(MotherLoaderEvent.COMPLETE_ALL, () => {
				let brandLeft = "2rem";
				if (jQuery(window).width() < 768) {
					brandLeft = "1.5rem";
				} else if (jQuery(window).width() < 1025) {
					brandLeft = "2.5rem";
				} else {
					brandLeft = "2rem";
				}
				gsap.to(brandEl, {
					left: brandLeft,
					duration: 0.75,
					ease: Quart.easeInOut,
				});
				gsap.to(textEl, { y: "110%", duration: 0.75, ease: Quart.easeInOut });
				gsap.to(siteLoaderEl, {
					autoAlpha: 0,
					duration: 0.75,
					delay: 0.5,
					ease: Quad.easeInOut,
					onComplete: () => {
						siteLoaderEl.remove();
					},
				});

				toggleNav();
				openContact();
				setTimeout(() => {
					jQuery(".hero video").trigger("play");
				}, 800);
				setupAnimations();
				setupHovers();
				resolve();
			});
			//   this._imgLoader = new ImagesLoaded(next.container, resolve);
		});
	}

	once() {
		return new Promise(resolve => {
			resolve();
			// const tl = gsap.timeline();
			// let count = 0;
			// const el = jQuery(".preloader span");
			// const SiteLoader = jQuery(".site-loader");
			// const Brand = jQuery(".site-loader .brand");

			// tl.set(".site-loader svg path", {
			// 	drawSVG: 0,
			// });

			// tl.to(SiteLoader, {
			// 	function() {
			// 		instance.destroy();
			// 		jQuery(".is-inview").removeClass("is-inview");
			// 	},
			// });

			// tl.fromTo(
			// 	".site-loader .preloader .number div",
			// 	{
			// 		y: "110%",
			// 	},
			// 	{
			// 		y: 0,
			// 		duration: 0.5,
			// 	},
			// );

			// tl.to(
			// 	Brand,
			// 	{
			// 		opacity: 1,
			// 	},
			// 	"<",
			// );

			// tl.to(SiteLoader, {
			// 	function() {
			// 		tl.to(".site-loader svg path", {
			// 			drawSVG: "100%",
			// 			duration: 2,
			// 			ease: "none",
			// 		});
			// 		let counter = setInterval(function () {
			// 			if (count < 101) {
			// 				jQuery(".site-loader .preloader .number div").text(`${count}%`);
			// 				jQuery(".site-loader .line").width(`${count}%`);
			// 				count++;
			// 			} else {
			// 				clearInterval(counter);
			// 				tl.to(".site-loader .preloader .number div", {
			// 					y: "110%",
			// 					onStart: () => {
			// 						resolve();
			// 					},
			// 					onComplete: () => { },
			// 				});
			// 				tl.to(
			// 					Brand,
			// 					{
			// 						duration: 0.5,
			// 						left: "2rem",
			// 						function() {
			// 							instance.init();
			// 							setTimeout(() => {
			// 								jQuery(".hero video").trigger("play");
			// 							}, 800);
			// 						},
			// 					},
			// 					"<",
			// 				);
			// 				tl.to(SiteLoader, {
			// 					duration: 0.5,
			// 					autoAlpha: 0,
			// 				});
			// 			}
			// 		}, 20);
			// 	},
			// });
		});
	}
}

export default SiteLoader;
