import ScrollTrigger from "gsap/ScrollTrigger";
import LocomotiveScroll from "./LocomotiveScroll";
import { $, body, html } from "@utils/dom";

export const SELECTOR = ".scroll-container";
export const SCROLL_MIN_CLASSNAME = "--js-scroll-min";
export const SCROLL_DOWN_CLASSNAME = "--js-scroll-down";
export const SCROLL_UP_CLASSNAME = "--js-scroll-up";

const SCROLL_THRESHOLD = 100;
const SCROLL_DIFF_THRESHOLD = 2;

export default class NewSiteScroll {
	constructor() {
		this.container = null;
		this.locoScroll = null;
		this.resizeObserver = null;
		this.scrollDirection = null;
		this.previousScrollY = null;

		this.init = this.init.bind(this);
		this.start = this.start.bind(this);
		this.update = this.update.bind(this);
		this.destroy = this.destroy.bind(this);
		this.onScroll = this.onScroll.bind(this);
	}

	init() {
		this.container = $(SELECTOR);
		this.hasScrolledAboveThreshold = false;
		this.scrollDirection = null;
		this.previousScrollY = 0;
		this.locoScroll = LocomotiveScroll(this.container, {
			smooth: true,
		});

		this.locoScroll.on("call", this._onCall);
		this.locoScroll.on("scroll", this.onScroll);

		this.resizeObserver = new ResizeObserver(() => {
			this.update();
		});

		this.resizeObserver.observe(this.container);
	}

	start() {
		if (this.locoScroll) {
			this.locoScroll.start();
		}
	}

	update() {
		if (this.locoScroll) {
			this.locoScroll.update();
		}

		try {
			ScrollTrigger.refresh();
		} catch (error) {}
	}

	destroy() {
		if (this.locoScroll) {
			this.locoScroll.off("call", this._onCall);
			this.locoScroll.off("scroll", this._onScroll);
			this.locoScroll.destroy();
		}

		if (this.resizeObserver) {
			this.resizeObserver.disconnect();
		}

		this.container = null;
		this.locoScroll = null;
		this.hasScrolledAboveThreshold = false;
		this.scrollDirection = null;
		this.previousScrollY = null;
		this.resizeObserver = null;
	}

	onScroll(instance) {
		const { y } = instance.scroll;
		const diff = y - this.previousScrollY;
		const direction = diff >= 0 ? "down" : "up";
		const threshold = y > SCROLL_THRESHOLD;
		const isDiffEnough = Math.abs(diff) >= SCROLL_DIFF_THRESHOLD;

		this.previousScrollY = y;

		this.emitter.emit(`${this.name}.scroll`, y);

		if (threshold === true && this.hasScrolledAboveThreshold === false) {
			this.hasScrolledAboveThreshold = true;
			this.state.dispatch("SCROLL_MIN", true);

			body.classList.add(SCROLL_MIN_CLASSNAME);
		} else if (threshold === false && this.hasScrolledAboveThreshold === true) {
			this.hasScrolledAboveThreshold = false;
			this.state.dispatch("SCROLL_MIN", false);

			body.classList.remove(SCROLL_MIN_CLASSNAME);
		}

		if (isDiffEnough && direction !== this.direction) {
			if (this.direction === null)
				body.classList.add(
					direction === "down" ? SCROLL_DOWN_CLASSNAME : SCROLL_UP_CLASSNAME,
				);
			else if (direction === "down")
				body.classList.replace(SCROLL_UP_CLASSNAME, SCROLL_DOWN_CLASSNAME);
			else body.classList.replace(SCROLL_DOWN_CLASSNAME, SCROLL_UP_CLASSNAME);

			this.scrollDirection = direction;

			this.state.dispatch("SCROLL_DIRECTION", direction);

			this.emitter.emit(`${this.name}.direction`, direction);
		}
	}

	get y() {
		return this.scroll ? this.scroll.scroll.instance.scroll.y : 0;
	}

	get direction() {
		return this.scrollDirection;
	}
}
