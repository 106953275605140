import { gsap } from "gsap";
import { SplitText } from "gsap/SplitText";
import Observer from "../ponce/event/Observer";
import {
	addClass,
	queryAll,
	removeClass,
	setProps
} from "../ponce/util/functions";
gsap.registerPlugin(SplitText);

Observer.create(0, { rootMargin: "0px", threshold: 0 });

const setupAnimations = () => {
	const SPLIT_MAP = {
		0: "chars",
		1: "words",
		2: "lines",
	};
	const elements = queryAll("[data-split]");
	const triggerAnimation = (el, entry, observer) => {
		if (entry.isIntersecting) {
			const { setup, keep, split, duration, delayFactor, delay } =
				el.attributes;

			const _duration = duration ? parseFloat(duration.value) : 0.5;
			const _delayFactor = delayFactor ? parseFloat(delayFactor.value) : 8;
			const _delay = delay ? parseFloat(delay.value) : 0.1;
			const spliValue =
				(split && split.value == "") || !split ? 2 : split.value;

			const lines = new SplitText(el, {
				type: "lines",
				linesClass: "line-container",
			});

			const splited = new SplitText(queryAll(".line-container", el), {
				type: SPLIT_MAP[spliValue],
				charsClass: "char",
				wordsClass: "word",
				linesClass: "line",
			});
			const parts = !spliValue
				? splited.chars
				: spliValue == 1
				? splited.words
				: splited.lines;
			setProps(el, { "--t": `${_duration}s` });
			parts.forEach((part, i) =>
				setProps(part, { "--d": `${i / _delayFactor + _delay}s` }),
			);

			if (!keep) {
				parts[parts.length - 1].addEventListener("transitionend", () => {
					splited.revert();
					lines.revert();
					removeClass(el, "show");
					el.removeAttribute("data-split");
				});
			}
			if (!setup) requestAnimationFrame(() => addClass(el, "show"));

			Observer.unobserve(observer.id, el);
		}
	};
	elements.forEach(el => Observer.observe(0, el, triggerAnimation));
};

export { setupAnimations };
