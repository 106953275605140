import axios from 'axios';
import ClipboardJS from 'clipboard';
import $ from "jquery";
import { TabulatorFull as Tabulator } from 'tabulator-tables';

function toggleNav() {
	$(".navbar .toggler").on("click", function () {
		$("body").toggleClass("is-menu-open");
	});

	$(".menu-mobile a").on("click", function () {
		$("body").removeClass("is-menu-open");
	});
}

function accordion() {
	var acc = document.getElementsByClassName("accordion");
	var i;

	for (i = 0; i < acc.length; i++) {
		acc[i].addEventListener("click", function () {
			this.classList.toggle("active");
			var panel = this.nextElementSibling;
			if (panel.style.maxHeight) {
				panel.style.maxHeight = null;
			} else {
				panel.style.maxHeight = panel.scrollHeight + "px";
			}
		});
	}
}

function orderBalls() {
	const container = document.querySelector("#projects .items");
	const balls = container.querySelectorAll(".item");

	const getCenterPoint = rect => {
		return { x: rect.x + rect.width * 0.475, y: rect.y + rect.height * 0.42 };
	};
	const cPoint = getCenterPoint(container.getBoundingClientRect());
	const getDistance = (p1, p2) => {
		const dX = p2.x - p1.x;
		const dY = p2.y - p1.y;
		return Math.hypot(dX, dY);
	};

	const sortedBallsArr = [...balls];
	const finalBallsArr = [];
	sortedBallsArr.sort((a, b) => {
		const aCPoint = getCenterPoint(a.getBoundingClientRect());
		const bCPoint = getCenterPoint(b.getBoundingClientRect());
		const dA = getDistance(cPoint, aCPoint);
		const dB = getDistance(cPoint, bCPoint);
		return dA - dB;
	});
	sortedBallsArr.forEach((el, i) => {
		const index = parseInt(el.getAttribute("key"));
		finalBallsArr[index] = container.children[i];
	});
	finalBallsArr.forEach(el => container.appendChild(el));
}

function playVideo() {
	$(".video").on("click", function () {
		if ($(this).hasClass("play")) {
			$(this).removeClass("play");
			$(this).find("video").trigger("pause");
			$("#cursor-wrapper").removeClass("pause");
		} else {
			$(this).addClass("play");
			$(this).find("video").trigger("play");
			$("#cursor-wrapper").addClass("pause");
		}
	});
}

function openContact() {
	$("[data-contact]").on("click", function(e) {
		e.preventDefault();
		e.stopPropagation();
		$("body").addClass("contact-open");
	})

	$(".modal-contact .close").on("click", function() {
		$("body").removeClass("contact-open");
	})

	$(document).mouseup(function(e)  {
		var container = $(".modal-contact");
		if (!container.is(e.target) && container.has(e.target).length === 0) {
			$("body").removeClass("contact-open");
		}
	});

	$(document).on('keydown', function(e) {
		if (e.key == "Escape") {
			$("body").removeClass("contact-open");
		}
	});
}

function copyToClipboard() {
	new ClipboardJS('.copy-link');
	$('.copy-link').on("click", function() {
		$(".copy-link .title span").text("Copied!");

		setTimeout(() => {
			$(".copy-link .title span").text("Copy link");
		}, 2000)
	});

	$('.copy-link.alt').on("click", function() {
		$(".copy-link.alt span").text("Copied!");

		setTimeout(() => {
			$(".copy-link.alt span").text("Copy link");
		}, 2000)
	});
}

const ajaxLoadMore = () => {

	const button = document.querySelector('.load-more');

	if (typeof (button) != 'undefined' && button != null) {

	  button.addEventListener('click', (e) => {
		e.preventDefault();

		let current_page = document.querySelector('.grid .items').dataset.page;
		let max_pages = document.querySelector('.grid .items').dataset.max;

		let params = new URLSearchParams();
		params.append('action', 'load_more_posts');
		params.append('current_page', current_page);
		params.append('max_pages', max_pages);

		axios.post('/wp-admin/admin-ajax.php', params)
		  .then(res => {

			let posts_list = document.querySelector('.grid .items');

			posts_list.innerHTML += res.data.data;

			document.querySelector('.grid .items').dataset.page++;

			if (document.querySelector('.grid .items').dataset.page == document.querySelector('.grid .items').dataset.max) {
			  button.parentNode.removeChild(button);
			}

		  })
	  	});
	}

	$('.cat-list_item').on('click', function() {
		$('.cat-list_item').removeClass('current-cat');
		$(this).addClass('current-cat');

		$.ajax({
		  type: 'POST',
		  url: '/wp-admin/admin-ajax.php',
		  dataType: 'html',
		  data: {
			action: 'filter_projects',
			category: $(this).data('slug'),
		  },
		  success: function(res) {
			$('.grid .items').html(res);
		  }
		})
	  });
}

function analyticsTable() {
	axios({
		method: "get",
		url: "/wp-content/themes/rly_network/api.php",
	}).then(function (response) {
		$("#totalWallets").text(response.data.totalWallets.toLocaleString());
		$("#totalTransactions").text(response.data.totalTransactions.toLocaleString());
		$("#tvl").text(response.data.tvl.toLocaleString());
		$("#totalRewards").text(response.data.totalRewards.toLocaleString())
		$(".overview li .value").removeClass("loading");
	});

	var tabledata = [
		{iconUrl: "https://i.imgur.com/gZM0Ll0.png", name:"Rally.io", token: "RLY", blockchain: "SiconUrlechain", rewards7: "23,456,789 RLY", rewards90: "123,456,789 RLY", projectedRewards: "0,123,456,789 RLY"},
		{iconUrl: "https://i.imgur.com/gZM0Ll0.png", name:"$GARY", token: "$GARY", blockchain: "Solana", rewards7: "23,456,789 RLY", rewards90: "123,456,789 RLY", projectedRewards: "0,123,456,789 RLY"},
		{iconUrl: "https://i.imgur.com/gZM0Ll0.png", name:"Taki.app", token: "TAKI", blockchain: "Solana", rewards7: "23,456,789 RLY", rewards90: "123,456,789 RLY", projectedRewards: "0,123,456,789 RLY"},
		{iconUrl: "https://i.imgur.com/gZM0Ll0.png", name:"Allie Coin", token: "ALLIE", blockchain: "Solana", rewards7: "23,456,789 RLY", rewards90: "123,456,789 RLY", projectedRewards: "0,123,456,789 RLY"},
		{iconUrl: "https://i.imgur.com/gZM0Ll0.png", name:"Scribe", token: "PLAY", blockchain: "Solana", rewards7: "23,456,789 RLY", rewards90: "123,456,789 RLY", projectedRewards: "0,123,456,789 RLY"},
		{iconUrl: "https://i.imgur.com/gZM0Ll0.png", name:"Joyride", token: "RLY", blockchain: "Flow", rewards7: "23,456,789 RLY", rewards90: "123,456,789 RLY", projectedRewards: "0,123,456,789 RLY"},
	];

	  var table = new Tabulator("#table", {
		// ajaxURL: "http://rly-rewards-staging.us-west-1.elasticbeanstalk.com/rewards_distributed",
		data: tabledata,
		layout: "fitColumns",
		resizableColumnFit: false,
		columns:[
			{title: " ", field: "iconUrl", formatter:"image", widthGrow: 0.5, frozen: true, headerSort: false},
			{title: "Name", field: "weeklyRewards.name", widthGrow: 1.5, frozen: true},
			{title: "Token", field: "token", widthGrow: 1.5},
			{title: "Blockchain", field: "blockchain", widthGrow: 1.5},
			{title: "Rewards 7 day", field: "rewards7", sorter: "number", widthGrow: 1.8},
			{title: "Rewards 90 days", field: "rewards90", sorter: "number", widthGrow: 1.8},
			{title: "Projected Rewards", field: "projectedRewards", sorter: "number", widthGrow: 1.8},
		],
	});
}

export { toggleNav, accordion, orderBalls, playVideo, openContact, copyToClipboard, ajaxLoadMore, analyticsTable };
