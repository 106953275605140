import { instance } from "@ui/site-scroll";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { InertiaPlugin } from "gsap/InertiaPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import $ from "jquery";

gsap.registerPlugin(ScrollTrigger, Draggable, InertiaPlugin);

gsap.config({
	nullTargetWarn: false,
});

function animationFeatures() {
	const fixedFeaturesEl = document.querySelector("#fixed-features");
	const ulEl = fixedFeaturesEl.querySelector("ul.list-web");
	const iconEl = fixedFeaturesEl.querySelector(".icon");
	const sceneEl = iconEl.querySelector("#scene");
	const context = sceneEl.getContext("2d");
	const sections = [...ulEl.children];

	const baseUrl =
		"/wp-content/themes/rly_network/dist/images/features_animation/";
	const totalFrames = 249;
	const images = {};

	const margin = {
		start: 0.6,
		end: 0.4,
	};
	const frameMap = {
		0: 50,
		1: 50,
		2: 50,
		3: 50,
		4: 49,
	};

	const init = () => {
		const img0 = loader.get(`frame_0`).el;
		sceneEl.width = img0.naturalWidth;
		sceneEl.height = img0.naturalHeight;

		for (let i = 0; i <= totalFrames; i++) {
			const ref = loader.get(`frame_${i}`);
			let img;
			if (ref) img = ref.el;
			else {
				img = new Image();
				img.src = `${baseUrl}${i}.webp`;
			}
			images[i] = img;
			// iconEl.appendChild(img);
		}
		instance.locoScroll.on("scroll", updateAnimation);
	};

	const updateAnimation = () => {
		let frame = 0;
		let img;

		sections.forEach((section, i) => {
			const rect = section.getBoundingClientRect();
			const animScreenSize = (margin.start - margin.end) * innerHeight;
			const startPosition = margin.start * innerHeight;
			const relativeSectionY = startPosition - rect.y;
			const pct = Math.min(Math.max(0, relativeSectionY / animScreenSize), 1);
			frame += Math.round((frameMap[i] || 0) * pct);
		});

		img = images[frame];
		// if (iconEl.children[0]) iconEl.children[0].remove();
		// iconEl.appendChild(img);
		context.clearRect(0, 0, sceneEl.width, sceneEl.height);
		context.drawImage(img, 0, 0);
	};

	init();

	document.querySelectorAll(".features ul.list-web li").forEach(item => {
		const height = $(".features ul.list-web li").outerHeight();
		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: item,
				scroller: "[data-scroll-container]",
				scrub: true,
				start: "top center",
				end: `+=${height}px`,
			},
		});

		tl.to(item, { autoAlpha: 1 }, 0)
			.to(
				item,
				{
					color: "#ceff45",
				},
				0.25,
			)
			.to(item, { autoAlpha: 0.3, color: "#fff" }, 1);
	});

	if ($(window).width() < 768) {
		gsap.set(
			"#fixed-features .list-mobile li h3, #fixed-features .list-mobile li p,  #fixed-features .list-mobile li .num",
			{
				opacity: 0,
			},
		);
		const container = $("#fixed-features").outerHeight();
		const card = $("section.features ul.list-web li").outerHeight() * 1.25;
		const containerEnd = container - card;
		gsap.timeline({
			scrollTrigger: {
				trigger: "#fixed-features .icon",
				scrub: true,
				pin: true,
				start: "top center",
				end: `+=${containerEnd}`,
			},
		});

		const circlePath = $(".list-mobile svg path");
		gsap.set(circlePath, { drawSVG: "0" });
		const tl = gsap
			.timeline({
				scrollTrigger: {
					trigger: "#fixed-features .list-mobile",
					scrub: true,
					pin: true,
					start: "-=100%",
					end: `+=${containerEnd}`,
				},
			})
			.to(
				"#fixed-features li:nth-child(1) h3, #fixed-features li:nth-child(1) p, #fixed-features li:nth-child(1) .num",
				{
					opacity: 1,
					duration: 0.6,
				},
				0,
			)
			.to(
				circlePath,
				{
					drawSVG: `20%`,
				},
				0,
			)
			.to(
				"#fixed-features li:nth-child(1) h3, #fixed-features li:nth-child(1) p, #fixed-features li:nth-child(1) .num",
				{
					opacity: 0,
					duration: 0.6,
				},
				0.5,
			)
			.to(
				"#fixed-features li:nth-child(2) h3, #fixed-features li:nth-child(2) p, #fixed-features li:nth-child(2) .num",
				{
					opacity: 1,
					duration: 0.6,
				},
				1,
			)
			.to(
				circlePath,
				{
					drawSVG: `40%`,
				},
				1,
			)
			.to(
				"#fixed-features li:nth-child(2) h3, #fixed-features li:nth-child(2) p, #fixed-features li:nth-child(2) .num",
				{
					opacity: 0,
					duration: 0.6,
				},
				1.5,
			)
			.to(
				"#fixed-features li:nth-child(3) h3, #fixed-features li:nth-child(3) p, #fixed-features li:nth-child(3) .num",
				{
					opacity: 1,
					duration: 0.6,
				},
				2,
			)
			.to(
				circlePath,
				{
					drawSVG: `60%`,
				},
				2,
			)
			.to(
				"#fixed-features li:nth-child(3) h3, #fixed-features li:nth-child(3) p, #fixed-features li:nth-child(3) .num",
				{
					opacity: 0,
					duration: 0.6,
				},
				2.5,
			)
			.to(
				"#fixed-features li:nth-child(4) h3, #fixed-features li:nth-child(4) p, #fixed-features li:nth-child(4) .num",
				{
					opacity: 1,
				},
				3,
			)
			.to(
				circlePath,
				{
					drawSVG: `80%`,
				},
				3,
			)
			.to(
				"#fixed-features li:nth-child(4) h3, #fixed-features li:nth-child(4) p, #fixed-features li:nth-child(4) .num",
				{
					opacity: 0,
					duration: 0.6,
				},
				3.5,
			)
			.to(
				"#fixed-features li:nth-child(5) h3, #fixed-features li:nth-child(5) p, #fixed-features li:nth-child(5) .num",
				{
					opacity: 1,
				},
				4,
			)
			.to(
				circlePath,
				{
					drawSVG: `100%`,
				},
				4,
			);
	} else if ($(window).width() < 1025) {
		const container = $("#fixed-features").outerHeight();
		const card = $("section.features ul.list-web li").outerHeight() * 1.75;
		const containerEnd = container - card;
		gsap.timeline({
			scrollTrigger: {
				trigger: "#fixed-features .icon",
				scrub: true,
				pin: true,
				start: "-=130%",
				end: `+=${containerEnd}`,
			},
		});
	}
}

function projectsParallax() {
	const section = $("section.projects");

	if (document.querySelector("section.projects")) {
		$(section).on("mousemove", function (e) {
			gsap.to("section.projects .content", {
				duration: 4,
				x: -(e.clientX - window.innerWidth / 2),
				y: -(e.clientY * 2 - window.innerHeight),
			});
		});

		$(function () {
			let time = 0;
			function cancel() {
				clearTimeout(time);
			}
			function reset() {
				time = setTimeout(function () {
					gsap.to("section.projects .content", {
						duration: 2,
						x: 0,
						y: 0,
					});
				}, 2000);
			}
			$(section).on("mouseleave", reset).on("mouseenter", cancel);
		});
	}
}

function projectsDraggable() {
	const section = $("section.projects");

	// Draggable.create("section.projects .content", {
	// 	type: "x,y",
	// 	edgeResistance: 0.5,
	// 	bounds: section,
	// 	inertia: true,
	// });
	// Draggable.get("section.projects .content").disable();

	$("section.projects .btn").on("click", function () {
		section.toggleClass("active");
		// $("body").addClass("block");
		// Draggable.get("section.projects .content").enable();
		// instance.stop();
	});

	// $("section.projects .leave .icon, .navbar .toggler").on("click", function () {
	// 	section.removeClass("active");
	// 	$("body").removeClass("block");
	// 	Draggable.get("section.projects .content").disable();
	// 	instance.start();
	// 	gsap.to("section.projects .content", {
	// 		duration: 1,
	// 		x: 0,
	// 		y: 0,
	// 	});
	// });
}

function animationProjects() {
	if ($(window).width() < 1025) {
		projectsDraggable();
	} else {
		projectsParallax();
	}
}

function animationWhy() {
	if (document.querySelector("section.why")) {
		gsap
			.timeline()
			.set(
				"section.why ul li:nth-child(1), section.why ul li:nth-child(4)",
				{
					rotate: "-90deg",
					x: "-150%",
				},
				0,
			)
			.set(
				"section.why ul li:nth-child(2)",
				{
					y: "150%",
				},
				0,
			)
			.set(
				"section.why ul li:nth-child(5)",
				{
					rotate: "-180deg",
					y: "50%",
				},
				0,
			)
			.set(
				"section.why ul li:nth-child(3), section.why ul li:nth-child(6)",
				{
					rotate: "90deg",
					x: "150%",
				},
				0,
			);

		let startTl = "-=110%";
		let endTl = "+=40%";

		if ($(window).width() >= 1025) {
			startTl = "-=80%";
			endTl = "+=130%";
		}

		gsap
			.timeline({
				scrollTrigger: {
					trigger: "section.why ul",
					start: `${startTl}`,
					end: `${endTl}`,
					scroller: "[data-scroll-container]",
					scrub: true,
				},
			})

			.to(
				"section.why ul .line.top",
				{
					width: "100%",
				},
				0,
			)
			.to(
				"section.why ul .line.center",
				{
					width: "100%",
				},
				0.25,
			)
			.to(
				"section.why ul .line.bottom",
				{
					width: "100%",
				},
				0.5,
			)
			.to(
				"section.why ul li:nth-child(2)",
				{
					y: 0,
				},
				0,
			)
			.to(
				"section.why ul li:nth-child(5)",
				{
					y: "-100%",
				},
				0,
			)
			.to(
				"section.why ul li:nth-child(1)",
				{
					rotate: 0,
					x: 0,
				},
				0,
			)
			.to(
				"section.why ul li:nth-child(3)",
				{
					rotate: 0,
					x: 0,
				},
				0,
			)
			.to(
				"section.why ul li:nth-child(4)",
				{
					rotate: 0,
					x: 0,
				},
				0.5,
			)
			.to(
				"section.why ul li:nth-child(6)",
				{
					rotate: 0,
					x: 0,
				},
				0.5,
			)
			.to(
				"section.why ul li:nth-child(5)",
				{
					rotate: 0,
					y: 0,
				},
				0.5,
			);
	}
}

function dragSlide() {
	if (document.querySelector("section.slide-drag")) {
		document.querySelectorAll("section.slide-drag .list").forEach(section => {
			const card = $(section).find(".card").outerWidth() / 16;
			const list = $(section).find(".list-inner");

			var draggable = new Draggable.create(list, {
				type: "x",
				edgeResistance: 0.5,
				dragResistance: 0.1,
				bounds: section,
				trigger: section,
				zIndexBoost: false,
				inertia: true,
				duration: 2,
				snap: {
					x: function (endValue) {
						return Math.round(endValue / card) * card;
					},
				},
			});
		});
	}
}

function developerSteps() {
	gsap
		.timeline({
			scrollTrigger: {
				trigger: ".steps",
				scroller: "[data-scroll-container]",
				scrub: true,
				start: "top top",
				end: `+=150%`,
				pin: true,
			},
		})
		.to(
			".steps .step:nth-child(1)",
			{ scale: "0.8", duration: 1, ease: "none" },
			0,
		)
		.to(
			".steps .step:nth-child(2)",
			{ y: "-95%", duration: 1, ease: "none" },
			0,
		)
		.to(
			".steps .step:nth-child(3)",
			{ y: "-95%", duration: 1, ease: "none" },
			0,
		)
		.to(
			".steps .step:nth-child(2)",
			{ scale: "0.9", duration: 1, ease: "none" },
			1,
		)
		.to(
			".steps .step:nth-child(3)",
			{ y: "-190%", duration: 1, ease: "none" },
			1,
		);
}

export {
	animationFeatures,
	animationProjects,
	animationWhy,
	dragSlide,
	developerSteps,
};
