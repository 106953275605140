const svgNs = 'http://www.w3.org/2000/svg';

/**
 *
 * @param {String} query
 * @param {HTMLElement} root
 * @returns
 */
const query = (query, root) => {
	return (root || document).querySelector(query);
}

/**
 *
 * @param {String} query
 * @param {HTMLElement} root
 * @returns
 */
const queryAll = (query, root) => {
	return (root || document).querySelectorAll(query);
}

const toArray = (el) => {
	return Array.isArray(el) ? el : [el]
}

/**
 *
 * @param {HTMLElement|HTMLElement[]} el
 * @param {Object} props
 */
const setProps = (el, props) => {
	el = toArray(el);
	for (let i in props) {
		el.forEach(_el => _el.style.setProperty(i, props[i]))
	}
}

const getProp = (el, prop) =>{
	return window.getComputedStyle(el || document.body).getPropertyValue(prop)
}

const setAttributes = (el, props) => {
	el = toArray(el);
	for (let i in props) {
		el.forEach(_el => _el.setAttribute(i, props[i]))
	}
}

const setAttributesNS = (el, props) => {
	el = toArray(el);
	for (let i in props) {
		el.forEach(_el => _el.setAttributeNS(null, i, props[i]))
	}
}

const addClass = (el, ...classname) => {
	el = toArray(el);
	el.forEach(_el => _el.classList.add(...classname));
}

const removeClass = (el, ...classname) => {
	el = toArray(el);
	el.forEach(_el => _el.classList.remove(...classname));
}

const hasClass = (el, classname) => {
	return el.classList.contains(classname);
}

const itemClass = (el, index) => {
	return el.classList.item(index);
}

const toggleClass = (el, classname, force) => {
	el.classList.toggle(classname, force);
}

/**
 *
 * @param {HTMLElement|HTMLElement[]} el
 * @param {Boolean} deep
 */
const clearProps = (el, deep) => {
	el = toArray(el);
	el.forEach(_el => {
		_el.removeAttribute('style')
		if (deep && _el.children && _el.children.length) clearProps([..._el.children], deep)
	})
}

/**
 * Quebra o texto em characteres
 * Falta aperfeiçoar a função para tratar tags dentro do elemento.
 * @param {HTMLElement} el
 * @param {Number=0,1,2} [type=0]
 * @param {Boolean} [masked=false]
 * @returns {HTMLElement[]}
 */
const splitText = (el, type = 0, masked = false, recursive = false) => {
	const childNodes = [...el.childNodes];
	const span = (content, nowrap) => `<span${nowrap ? ' style="white-space:nowrap;display:inline-flex;"' : ''}>${content}</span>`
	const maskHTML = content => `<span style="overflow:hidden;display:inline-flex;">${content}</span>`
	const format = (match, capture) => {
		let content = span(match, !masked && capture === undefined);
		return masked ? maskHTML(content) : content;
	}

	el.innerHTML = "";

	childNodes.forEach(child => {
		const children = child.children;
		if (children) el.appendChild(child);
		else {
			// const htmlPart = el.textContent.replace(/\S+/g, type == 1 && masked ? maskHTML(span) : span)
			child.textContent.split('\n').forEach(line => {
				if (!line) return;
				const text = line.trim();
				const newLine = type == 2 ? format(text) : text.replace(/\S+/g, (match) => { return type ? format(match) : span(match.replace(/\S|-/g, format), true) });
				el.insertAdjacentHTML('beforeend', newLine);
			});
		}
	});

	const _children = el.querySelectorAll(!type ? (masked ? 'span span span' : 'span span') : masked ? 'span span' : 'span')
	el._children = _children
	return _children
}

/**
 *
 * @param {HTMLElement} el
 * @returns {DOMRect}
 */
const getRect = el => {
	const rect = el == window ? { x: 0, y: 0, width: innerWidth, height: innerHeight } : el.getBoundingClientRect();
	rect.maxX = rect.x + rect.width;
	rect.maxY = rect.y + rect.height;
	rect.cX = rect.x + (rect.width * .5);
	rect.cY = rect.y + (rect.height * .5);
	return rect;
}

/**
 * @private
 * @param {HTMLElement} el
 * @param {String} name
 * @param {HTMLElement} child
 */
const addDOMProp = (el, name, child) => {
	const filter = ['text', 'title', 'name'];
	if (filter.indexOf(name) > -1) name = '_' + name;
	let value = el[name]
	if (value) {
		if (!value.length) el[name] = [value]
		el[name].push(child)
	} else el[name] = child
}

/**
 *
 * @param {HTMLElement} el
 */
const clearDOM = el => {
	let children = el.children
	for (let child of children) {
		let tagName = child.tagName.toLowerCase()
		let classList = child.classList
		let id = child.id
		delete el[tagName]
		delete el[id]
		if (classList.length) {
			let className = classList[0]
			delete el[className]
		}
		if (child.children.length) {
			clearDOM(child)
		}
	}
}

/**
 *
 * @param {HTMLElement} el
 * @returns {HTMLElement}
 */
const getDOM = el => {
	clearDOM(el)
	let children = el.children
	for (let child of children) {
		let tagName = child.tagName.toLowerCase()
		let classList = child.classList
		let id = child.id
		addDOMProp(el, tagName, child)
		addDOMProp(el, id, child)
		if (classList.length) {
			let className = classList[0]
			addDOMProp(el, className, child)
		}
		if (child.children.length) {
			getDOM(child)
		}
	}
	return el
}

/**
 *
 * @param {EventTarget} el
 * @param {String} events
 * @param {Function} call
 */
const addEventListeners = (el, events, call, props) => {
	events.split(' ').forEach(event => {
		el.addEventListener(event, call, props)
	})
}

/**
 *
 * @param {EventTarget} el
 * @param {String} events
 * @param {Function} call
 */
const removeEventListeners = (el, events, call) => {
	events.split(' ').forEach(event => {
		el.removeEventListener(event, call)
	})
}

/**
 *
 * @param {MouseEvent} e
 * @param {Boolean} absolute
 * @returns {{x:Number, y:Number}}
 */
const getCursorPoint = (e, absolute) => {
	let target = e.currentTarget;
	let rect = getRect(target);
	let point = e.changedTouches ? e.changedTouches[0] : e
	return absolute ? { x: point.clientX, y: point.clientY } : { x: point.clientX - rect.x, y: point.clientY - rect.y }
}

/**
 *
 * @param {HTMLElement} el
 * @returns {{x:Number, y:Number}}
 */
const getCenterPoint = (el) => {
	let bouding = getRect(el);
	return { x: (bouding.x + bouding.width * .5), y: (bouding.y + bouding.height * .5) }
}

/**
 *
 * @returns {Boolean}
 */
const isMobileDevice = () => {
	const toMatch = [
		/Android/i,
		/webOS/i,
		/iPhone/i,
		/iPad/i,
		/iPod/i,
		/BlackBerry/i,
		/Windows Phone/i
	];

	return toMatch.some((toMatchItem) => {
		return navigator.userAgent.match(toMatchItem);
	});
}

/**
 *
 * @returns {String}
 */
const randomizeHexColor = () => {
	return `#${Math.round(Math.random() * 0xFFFFFF).toString(16)}`;
}

/**
 *
 * @param {HTMLElement} el
 */
const isVisible = (el) => {
	return (el.offsetWidth > 0 && el.offsetHeight > 0);
}

/**
 *
 * @param {String} nomeDaTag
 * @param {Object} tagName
 * @returns
 */
const createElement = (tagName, attributes) => {
	const el = document.createElement(tagName);
	if (attributes) {
		for (let key in attributes) el.setAttribute(key, attributes[key])
	}
	return el;
}

const createElementNS = (tagName, attributes) => {
	const el = document.createElementNS(svgNs, tagName);
	if (attributes) {
		for (let key in attributes) el.setAttributeNS(null, key, attributes[key])
	}
	return el;
}

/**
 *
 * @param {String} url
 * @returns
 */
const toSeoUrl = (url) => {
	return url.toString()
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '')
		.replace(/\s+/g, '-')
		.toLowerCase()
		.replace(/&/g, '-and-')
		.replace(/[^a-z0-9\-]/g, '')
		.replace(/-+/g, '-')
		.replace(/^-*/, '')
		.replace(/-*$/, '');
}

/**
 *
 * @param {String} value
 */
const splitValue = (value) => {
	let index = value.length;
	let unit = ""
	do {
		index--;
		const char = value.charAt(index);
		if (!isNaN(char)) break;
		else unit = char + unit;

	} while (index > 0);

	const obj = { value: parseFloat(value.substring(0, index + 1)), unit: unit }
	return obj
}

const wheelNormalize = (e) => {
	let o = e,
		d = o.detail,
		w = o.wheelDeltaY,
		n = 225,
		n1 = n - 1;
	d = d ? w && (f = w / d) ? d / f : -d / 1.35 : w / 120;
	d = d < 1 ? d < -1 ? (-Math.pow(d, 2) - n1) / n : d : (Math.pow(d, 2) + n1) / n;
	e.delta = Math.min(Math.max(d / 2, -1), 1);

	return e;
}

const getScreenSize = () => {
	return screen.width <= 1024 ? 1 : (screen.width <= 1920 ? 2 : 3);
}

const canUseWebP = () => {
	return new Promise(res => {
		const webP = new Image();
		webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
		webP.onload = webP.onerror = () => {
			res(webP.height === 2);
		};
	})
}

const canUseAvif = () => {
	return new Promise(res => {
		const avif = new Image();
		avif.src = "data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=";
		avif.onload = avif.onerror = () => {
			res(avif.height === 2);
		};
	})
}

const getFontSize = (el) => {
	return parseFloat(getProp(el || document.documentElement, 'font-size'));
}

function pixelToRem(px, fontSize) {
	return `${px / (fontSize || getFontSize())}rem`;
}

const getExtension = (filename) => {
	const ext = filename.match(/\.([^\./\?]+)($|\?)/);
	return ext ? ext[1].toLowerCase() : null;
}

const searchInManifest = (fileName, pathArr) => {
	const manifest = window.manifest;
	if (!manifest || !fileName || !pathArr) return fileName;

	let obj = manifest;
	for (let i = 0; i < pathArr.length; i++) {
		obj = obj[pathArr[i]];
		if (!obj) return fileName;
	}
	const hash = obj[fileName];
	if (hash != undefined) {
		const extension = getExtension(fileName);
		fileName = fileName.replace(`.${extension}`, `${hash ? `.${hash}` : ""}.${extension}`);
	} else fileName = null;
	return fileName;
}

const debounce = (func, timeout = 100) => {
	let timer;
	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => { func.apply(this, args); }, timeout);
	};
}

const getFullSize = () =>{
	let ref = query('#height-ref');
	if(!ref) {
		ref = createElement('div', {id:'height-ref'});
		setProps(ref,{position:'fixed', width:'100vw', height:'100vh', 'z-index':-1});
	}
	document.body.appendChild(ref);
	const width = ref.clientWidth;
	const height = ref.clientHeight;
	ref.remove();
	return {width:width, height:height}
}

const destroyInstance = (instance)=>{
	const signs = Object.getOwnPropertyNames(Object.getPrototypeOf(instance));
	for(let i in instance) {
		instance[i] = null;
		delete instance[i];
	}
	signs.forEach(sign => {
		instance[sign] = null;
		delete instance[sign];
	});
}

export { splitValue, setProps, clearProps, addClass, removeClass, hasClass, itemClass, toggleClass, splitText, getDOM, getRect, addEventListeners, removeEventListeners, getCursorPoint, getCenterPoint, isMobileDevice, randomizeHexColor, query, queryAll, isVisible, createElement, createElementNS, toSeoUrl, wheelNormalize, getExtension, getScreenSize, canUseWebP, canUseAvif, getFontSize, pixelToRem, searchInManifest, setAttributes, setAttributesNS, debounce, getFullSize, destroyInstance }
